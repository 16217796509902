import { template as template_35b6dc0625144b49885590929d684ddd } from "@ember/template-compiler";
const SidebarSectionMessage = template_35b6dc0625144b49885590929d684ddd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
