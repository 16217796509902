import { template as template_70eeca52f439447da23c7cd210342e7a } from "@ember/template-compiler";
const FKLabel = template_70eeca52f439447da23c7cd210342e7a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
