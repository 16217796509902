import { template as template_51c1fa79dede4aac9f5a22050674258d } from "@ember/template-compiler";
const WelcomeHeader = template_51c1fa79dede4aac9f5a22050674258d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
